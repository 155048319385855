























































































































































































































import { Vue, Component } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import {
  CommonStore as commonstore,
  MetaModule as metaStore,
  VehicleEntryModule as entryStore,
} from "@/store/modules";
import { VehicleList } from "@/store/models/meta";
import NepaliDatePicker from "@/components/NepaliDatePicker.vue";
import helpers from "@/utils/helpers";
import { BSToAD } from "bikram-sambat-js";

@Component({
  components: {
    NepaliDatePicker,
  },
})
export default class New extends Vue {
  isCreate: boolean = true;
  options: VehicleList[] = [] as any;
  loading: boolean = false;
  receipt: File[] = [];
  newReceiptUploaded: boolean = false;

  async created() {
    let vm = this;
    const id = vm.$route.query["id"];
    await metaStore.loadVehicleListDD();

    if (id) {
      vm.isCreate = false;
      this.options = metaStore.vehicleList;
      await entryStore.getVehicleEntryById(id);
    } else {
      entryStore.resetField();
    }
  }

  get VehicleList() {
    return metaStore.vehicleList;
  }

  get AdminRouter() {
    return AdminRouter;
  }

  get entry() {
    return entryStore.vehicleEntry;
  }

  bsDateChangeStart(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      this.entry.startDate = bsAD;
    }
    this.checkDaysForPositive();
  }

  bsDateChangeEnd(e: any) {
    if (e && e !== null) {
      const bsAD = BSToAD(e);
      this.entry.endDate = bsAD;
    }
    this.checkDaysForPositive();
  }

  remoteMethod(query: any) {
    if (query !== "") {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.options = metaStore.vehicleList.filter((item) => {
          return (
            item.vehicleNumber.toLowerCase().indexOf(query.toLowerCase()) > -1
          );
        });
      }, 200);
    } else {
      this.options = metaStore.vehicleList;
    }
  }

  handleChange(file: any, fileList: any) {
    let vm = this;
    const fileDocsExt = file.raw.name.split(".").length - 1;
    if (
      file.raw.name.split(".")[fileDocsExt] === "png" ||
      file.raw.name.split(".")[fileDocsExt] === "jpeg" ||
      file.raw.name.split(".")[fileDocsExt] === "jpg"
    ) {
      vm.receipt = [file.raw];
      vm.entry.permitReceipt = file.raw;
      this.newReceiptUploaded = true;
    } else {
      this.$snotify.error("Please upload .png or .jpeg or .jpgfile");
      file = "";
      vm.receipt = [];
      vm.entry.permitReceipt = null;
    }
  }

  handleRemove() {
    this.receipt = [] as File[];
    this.entry.permitReceipt = null;
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    let positiveDiff = this.entry.totalDays >= 0 ? true : false;
    if (!positiveDiff || !isValid) {
      this.$snotify.warning("Please check for errors");
      return;
    }
    let vm = this;
    commonstore.showLoading();

    if (this.entry.id) {
      helpers.removeFilesForPatch([
        {
          property: this.entry,
          fieldName: "permitReceipt",
        },
      ]);
    }

    await entryStore.createVehicleEntry();
    this.$snotify.success("Saved Successfully");
    commonstore.hideLoading();
    this.$router.push(AdminRouter.VehicleEntryList);
  }

  async cancelForm() {
    this.$router.push(AdminRouter.VehicleEntryList);
  }

  checkDaysForPositive() {
    if (this.entry.endDate && this.entry.startDate) {
      this.entry.totalDays = helpers.differenceOfTwoDates(
        this.entry.endDate,
        this.entry.startDate
      );
    }
    if (this.entry.totalDays < 0) {
      this.$notify({
        title:
          "Please correct permit dates. Total Permit Days should not be negative",
        message: "",
        type: "warning",
        position: "top-right",
      });
    }
  }
}
