var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"marginWrapper el-row--padding"},[_c('div',{staticClass:"flex flexColumn"},[_c('el-row',[_c('el-col',[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"flex verticleCentering spaceBetween",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("One Time Route Permit(Patake) Registration")]),_c('div',{staticClass:"textRight"},[_c('router-link',{attrs:{"to":_vm.AdminRouter.VehicleEntryList}},[_c('el-button',{attrs:{"type":"primary el-button--mini","plain":"","round":""}},[_c('i',{staticClass:"el-icon-d-arrow-left"}),_vm._v("Back ")])],1)],1)]),_c('el-form',{attrs:{"label-position":'left',"label-width":"150px","enctype":"multipart/form-data","multiple":""}},[_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{class:{
                    'is-error': _vm.fieldHasErrors('Vehicle Number'),
                    'is-required': _vm.isFieldRequired('Vehicle Number'),
                  },attrs:{"label":"Vehicle Number"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"filterable":"","remote":"","reserve-keyword":"","name":"Vehicle Number","placeholder":"Please enter a keyword","remote-method":_vm.remoteMethod},model:{value:(_vm.entry.vehicle),callback:function ($$v) {_vm.$set(_vm.entry, "vehicle", $$v)},expression:"entry.vehicle"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.vehicleNumber,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Vehicle Number'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Vehicle Number")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{class:{
                    'is-error': _vm.fieldHasErrors('Permit Start Date'),
                    'is-required': _vm.isFieldRequired('Permit Start Date'),
                  },attrs:{"label":"Start Date"}},[_c('NepaliDatePicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"date","placeholder":"Permit Start Date","name":"Permit Start Date"},on:{"change":_vm.bsDateChangeStart},model:{value:(_vm.entry.startNepaliDate),callback:function ($$v) {_vm.$set(_vm.entry, "startNepaliDate", $$v)},expression:"entry.startNepaliDate"}}),(_vm.errors.has('Permit Start Date'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Permit Start Date")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{class:{
                    'is-error': _vm.fieldHasErrors('Description'),
                    'is-required': _vm.isFieldRequired('Description'),
                  },attrs:{"label":"Description"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Description","name":"Description"},model:{value:(_vm.entry.description),callback:function ($$v) {_vm.$set(_vm.entry, "description", $$v)},expression:"entry.description"}}),(_vm.errors.has('Description'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Description")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{class:{
                    'is-error': _vm.fieldHasErrors('Permit End Date'),
                    'is-required': _vm.isFieldRequired('Permit End Date'),
                  },attrs:{"label":"End Date"}},[_c('NepaliDatePicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"date","placeholder":"Permit End Date","name":"Permit End Date"},on:{"change":_vm.bsDateChangeEnd},model:{value:(_vm.entry.endNepaliDate),callback:function ($$v) {_vm.$set(_vm.entry, "endNepaliDate", $$v)},expression:"entry.endNepaliDate"}}),(_vm.errors.has('Permit End Date'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Permit End Date")))]):_vm._e()],1),_c('br')],1),_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{attrs:{"label":"Total Permit Days"}},[_c('el-tag',{staticStyle:{"font-size":"14px","font-weight":"bold"},attrs:{"type":_vm.entry.totalDays < 0 ? 'danger' : 'success',"effect":"plain"}},[_vm._v(" "+_vm._s(_vm.entry.totalDays || 0)+" days ")]),(_vm.entry.totalDays < 0)?_c('small',{staticClass:"error",staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(" Please check start and end date ")]):_vm._e()],1)],1),_c('el-col',{staticStyle:{"margin-top":"0px"},attrs:{"span":10}},[_c('el-form-item',{class:{
                    'is-error': _vm.fieldHasErrors('Permit Receipt'),
                    'is-required': _vm.isFieldRequired('Permit Receipt'),
                  },attrs:{"label":"Documents"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"name":"Permit Receipt","on-change":_vm.handleChange,"on-remove":_vm.handleRemove,"file-list":_vm.receipt}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.isCreate ? 'required|image' : ''),expression:"isCreate ? 'required|image' : ''"}],attrs:{"size":"small","type":"primary","round":""},model:{value:(_vm.receipt),callback:function ($$v) {_vm.receipt=$$v},expression:"receipt"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),(_vm.entry.permitReceipt && !_vm.newReceiptUploaded)?_c('div',[_c('a',{attrs:{"href":_vm.entry.permitReceipt,"target":"_blank"}},[_vm._v(" permitReceipt."+_vm._s(_vm.entry.permitReceipt.split(".")[ _vm.entry.permitReceipt.split(".").length - 1 ])+" ")])]):_vm._e(),(
                      !_vm.entry.permitReceipt && !_vm.errors.has('Permit Receipt')
                    )?_c('small',{staticClass:"error",staticStyle:{"margin-top":"10px","font-weight":"bold"}},[_vm._v(" Upload files of extensions .png or .jpeg ")]):_vm._e(),(_vm.errors.has('Permit Receipt'))?_c('small',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errors.first("Permit Receipt"))+" ")]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',[_c('el-form-item',{staticClass:"textRight"},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":_vm.submit}},[_vm._v("Save")]),_c('el-button',{attrs:{"type":"danger","round":""},on:{"click":_vm.cancelForm}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }